import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import form_helper from 'libs/form-lib';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from 'components/cmp_icon';
import { FORM_DETAILSGROUP, FORM_SELECT, FORM_TOGGLE, INPUT, SELECT, TOGGLE } from 'components/cmp_form/cmp_form';
import 'i18n';

import './crd_settings.css';
import Processing from 'components/cmp_processing';



export default function CRD_SETTINGS({ step, onChange_step, credential, onChange_credential, display_processing }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_opened, set_opened ] = useState(false)
    const [ var_errors, set_errors ] = useState([]);
    const [ var_categories, set_categories ] = useState([]);
    const [ var_show_checkmark, set_show_checkmark ] = useState(false);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_categories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set_errors([]);
        if (step === 'SETTINGS') {
            set_opened(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if ((credential.has_wallet_feature === 'NO' || (credential.has_wallet_feature === 'YES' && credential.indv_or_org)) &&
            credential.credential_category_id &&
            credential.status &&
            (credential.has_verifiable_credential_feature === 'NO' || (credential.has_verifiable_credential_feature === 'YES' && credential.verifiable_credential)) &&
            ((credential.expires === 'YES' && form_helper.validate_positive_number(credential.expires_amount) && credential.expires_unit) || credential.expires === 'NO' || credential.has_prequalifier === 'YES') &&
            credential.auto_verify &&
            credential.issuedrequired &&
            ((credential.credential_number_required === 'YES' && credential.credential_number_label) || credential.credential_number_required === 'NO' || credential.has_prequalifier === 'YES') &&
            (credential.has_credit_value === 'NO' || form_helper.validate_positive_number(credential.credit_value))) {
            set_show_checkmark(true);
        } else {
            set_show_checkmark(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [credential]);


    //  async functions ------------------------------------------------------------------------------------------------


    async function populate_categories() {
        try {
            let results = (await API_get_credential_category_list()).filter(item => item.id !== 'ASSET_INSPECTION_REPORT');
            let transformed = results.map(item => ({ value: item.id, text: t(item.id) }));
            transformed = transformed.sort((a, b) => a.text - b.text);
            set_categories(transformed);
        } catch (e) {
            console.log(e);
        }
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_credential_category_list() {
        return API.get('credentials', '/get-credential-category-list');
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onClick_edit() {
        onChange_step('SETTINGS');
    }

    function onChange_input(event, { name, value }) {
        let updated_credential = {...credential};
        switch (name) {
            case 'expires_amount':
                value = value.replace(/\D/,'');
                break;
            case 'expires':
                if (value === 'NO') {
                    updated_credential.expires_amount = null;
                    updated_credential.expires_unit = null;
                }
                break;
            case 'credential_number_required':
                if (value === 'NO') {
                    updated_credential.credential_number_label = null;
                }
                break;
            case 'credit_value':
                value = form_helper.format_decimal_string(value, 3, 2);
                break;
            default:
                break;
        }
        updated_credential[name] = value;
        onChange_credential(updated_credential);
        set_errors(var_errors.filter(error => error.property !== name));
    }

    async function onClick_nextstep() {
        let updated_credential = {...credential};
        let errors = [];
        //validation
        if (!updated_credential.indv_or_org) {
            errors.push({ property: 'indv_or_org', description: t('This setting is required') });
        }
        if (!updated_credential.credential_category_id) {
            errors.push({ property: 'credential_category_id', description: t('Category is required') });
        }
        if (!updated_credential.status) {
            errors.push({ property: 'status', description: t('This setting is required') });
        }
        if (!updated_credential.verifiable_credential) {
            errors.push({ property: 'verifiable_credential', description: t('This setting is required') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && !updated_credential.expires) {
            errors.push({ property: 'expires', description: t('This setting is required') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && updated_credential.expires === 'YES' && !form_helper.validate_positive_number(updated_credential.expires_amount)) {
            errors.push({ property: 'expires_amount', description: t('Please add a valid expiration value.') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && updated_credential.expires === 'YES' && (!updated_credential.expires_unit)) {
            errors.push({ property: 'expires_unit', description: t('Please add a valid expiration duration.') });
        }
        if (!updated_credential.restrict_claims) {
            errors.push({ property: 'restrict_claims', description: t('This setting is required') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && !updated_credential.auto_verify) {
            errors.push({ property: 'auto_verify', description: t('This setting is required') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && !updated_credential.issuedrequired) {
            errors.push({ property: 'issuedrequired', description: t('This setting is required') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && !updated_credential.credential_number_required) {
            errors.push({ property: 'credential_number_required', description: t('This setting is required') });
        }
        if (updated_credential.has_prequalifier !== 'YES' && updated_credential.credential_number_required === 'YES' && !form_helper.validate_required_string(updated_credential.credential_number_label)) {
            errors.push({ property: 'credential_number_label', description: t('Please add a valid credential label.') });
        }
        if (updated_credential.has_credit_value === 'YES' && !form_helper.validate_positive_number(updated_credential.credit_value)) {
            errors.push({ property: 'credit_value', description: t('Please add a valid credential value.') });
        }
        set_errors(errors);
        if (errors.length > 0) return;

        form_helper.cleanse_string_property(updated_credential, 'credential_number_label');
        form_helper.cleanse_decimal_property(updated_credential, 'credit_value');
        onChange_credential(updated_credential);
        onChange_step('ACCESS')
    }



    // RENDER APP ======================================================================================================

    return (
        <div className='card rounded-lg shadow-sm' id='crd_create2'  tabIndex='0'>

            <div className='card__header'>
                <div className='card__header__icon'>
                    {(var_opened && step !== 'SETTINGS' && var_show_checkmark) ?
                        <Icon name='checkmark' className='icon__checkmark' alt='checkmark icon' />
                        :
                        <div className={'card__header__icon__step text--xl-bold' + (var_opened ? ' icon--dark_gray' : ' icon--light_gray')}>2</div>
                    }
                </div>
                <div className='card__header__left text'>
                    <div className='text--xl-medium' id='hdr_credential_settings'>{t('Credential settings')}</div>
                    {(!var_opened || step === 'SETTINGS') &&
                        <div className='text--sm-regular'>{t('Set the type of credential, issued date, expiry, and other attributes of the credential.')}</div>
                    }
                </div>
                <div className='card__header__right card__header__btns'>
                    {(var_opened && step !== 'SETTINGS') &&
                        <Button className='secondary' onClick={onClick_edit}>{t('Edit')}</Button>
                    }
                </div>
            </div>

            {(step !== 'SETTINGS' && var_opened) ?
                <>
                    <div className='card__content create_summary'>
                        {credential.has_wallet_feature === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Type')} value={credential.indv_or_org === 'INDV' ? t('Individual') : credential.indv_or_org === 'ORG' ? t('Organization') : ''} />
                        }
                        <FORM_DETAILSGROUP label={t('Category')} value={t(credential.credential_category_id)} />
                        <FORM_DETAILSGROUP label={t('Status')} value={credential.status === 'ACTIVE' ? t('Published') : credential.status === 'PENDING' ? t('Unpublished') : ''} />
                        {credential.has_verifiable_credential_feature === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Verifiable credential')} value={credential.verifiable_credential ? t(credential.verifiable_credential.substring(0, 1) + credential.verifiable_credential.substring(1).toLowerCase()) : ''} />
                        }
                        {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Expires')} value={credential.expires ? t(credential.expires.substring(0, 1) + credential.expires.substring(1).toLowerCase()) : ''} />
                        }
                        {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' && credential.expires === 'YES' &&
                            <>
                                <FORM_DETAILSGROUP label={t('Expiry value')} value={credential.expires_amount} />
                                <FORM_DETAILSGROUP label={t('Expiry duration')} value={t(credential.expires_unit)} />
                            </>
                        }
                        <FORM_DETAILSGROUP label={t('Restrict claims')}
                                               value={credential.restrict_claims ? t(credential.restrict_claims.substring(0, 1) + credential.restrict_claims.substring(1).toLowerCase()) : ''}/>
                        {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Auto-verify')}
                                               value={credential.auto_verify ? t(credential.auto_verify.substring(0, 1) + credential.auto_verify.substring(1).toLowerCase()) : ''}/>
                        }
                        {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('Issued date')}
                                               value={credential.issuedrequired ? t(credential.issuedrequired.substring(0, 1) + credential.issuedrequired.substring(1).toLowerCase()) : ''}/>
                        }
                        {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                            <FORM_DETAILSGROUP label={t('ID')} value={credential.credential_number_required ? t(credential.credential_number_required.substring(0, 1) + credential.credential_number_required.substring(1).toLowerCase()) : ''} />
                        }
                        {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' && credential.credential_number_required === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Field label')} value={credential.credential_number_label} />
                        }
                        <FORM_DETAILSGROUP label={t('Credit value')} value={credential.has_credit_value === 'YES' ? credential.credit_value : t('No')} />
                        {credential.has_notification_email &&
                            <FORM_DETAILSGROUP label={t('Issuance email')} value={credential.notify_issuance === 'YES' ? t('Yes') : t('No')} />
                        }
                        {credential.has_prequalifier !== 'YES' && credential.can_select_contains_pii === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Contains PII')} value={credential.contains_pii === 'YES' ? t('Yes') : t('No')} />
                        }
                        {credential.has_prequalifier === 'YES' &&
                            <FORM_DETAILSGROUP label={t('Show pre-qualifications')} value={credential.show_prequalifiers_in_certificate === 'YES' ? t('Yes') : t('No')} />
                        }
                    </div>

                    <div className='card__footer--mobilebtns'>
                        <Button className='secondary' onClick={() => onClick_edit()}>{t('Edit')}</Button>
                    </div>

                </>
                : step === 'SETTINGS' ?
                    <Form className='card__content' id='form_cr8credential_step2' aria-labelledby='hdr_credential_settings'>
                        <>
                            {var_errors.length > 0 &&
                                <Message
                                    error icon={<Icon name='error' className='icon'/>}
                                    header={t('There are some errors with your inputs')}
                                />
                            }

                            {credential.has_wallet_feature === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Type')}
                                    value={credential.indv_or_org || 'INDV'}
                                    options={[{value: 'INDV', text: t('Individual')}, { value: 'ORG', text: t('Organization') }]}
                                    onChange={(value) => onChange_input(null, { name: 'indv_or_org', value })}
                                    description={t('An “Individual” credential can be issued to, and held by, a person. An “Organization” credential can be issued to, and held by, a business. The credential type can not be changed after it has been set.')}
                                />
                            }

                            <FORM_SELECT
                                property='credential_category_id'
                                label={t('Category')}
                                value={credential.credential_category_id || ''}
                                onChange={onChange_input}
                                placeholder={t('Category')}
                                options={var_categories}
                                disabled={false}
                                errors={var_errors}
                                description={t('Categories help sort credentials in an individual\'s wallet and control how they can be shared.')}
                            />

                            <FORM_TOGGLE
                                label={t('Status')}
                                value={credential.status}
                                options={[{ value: 'ACTIVE', text: t('Published') }, { value: 'PENDING',text: t('Unpublished') }]}
                                onChange={(value) => onChange_input(null, { name: 'status', value })}
                                disabled={credential.has_prequalifier === 'YES' || credential.credits_required_for_issuance === 'YES'}
                                message_type={(credential.has_prequalifier === 'YES' || credential.credits_required_for_issuance === 'YES') ? 'WARNING' : null}
                                message_text={credential.has_prequalifier === 'YES'
                                    ? t('This credential will remain unpublished until pre-qualification requirements have been added.')
                                    : t('This credential will remain unpublished until credit tasks have been added.')}
                                description={t('A “Published” credential can be issued immediately. An “Unpublished” credential can not be issued until its status is updated to published.')}
                            />

                            {credential.has_verifiable_credential_feature === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Verifiable credential')}
                                    value={credential.verifiable_credential}
                                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'verifiable_credential', value })}
                                    description={t('This credential is available as a verifiable credential.')}
                                />
                            }

                            {credential.verifiable_credential === 'YES' && credential.has_proof_type === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Select the format of the linked data proof for this verifiable credential. Once the credential is published the selected format cannot be changed.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <TOGGLE
                                                value={credential.proof_type}
                                                options={[{ value: 'JWT', text: t('JWT') }, { value: 'JSONLD', text: t('JSON-LD') }]}
                                                onChange={(value) => onChange_input(null, { name: 'proof_type', value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Issued date')}
                                    value={credential.issuedrequired}
                                    options={[{ value: 'YES', text: t('Required') }, { value: 'NO', text: t('Not required') }]}
                                    onChange={(value) => onChange_input(null, { name: 'issuedrequired', value })}
                                    description={t('When a claim is made for this credential capture the date it was issued.')}
                                />
                            }

                                {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                                    <FORM_TOGGLE
                                        label={t('Expires')}
                                        value={credential.expires}
                                        options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                        onChange={(value) => onChange_input(null, { name: 'expires', value })}
                                        message_type={(credential.expires === 'YES' && credential.issuedrequired !== 'YES') ? 'WARNING' : null}
                                        message_text={t('When specifying an expiration date, an issued date is typically required. Please review your issued date settings.')}
                                        description={t('This credential expires.')}
                                    />
                                }

                            {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' && credential.expires === 'YES' &&
                                <div id='subgroup_expiry' className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Specify the timeframe for expiry.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Value')}</div>
                                            <INPUT
                                                property='expires_amount'
                                                value={credential.expires_amount}
                                                onChange={onChange_input}
                                                placeholder='0'
                                                maxLength={2}
                                                errors={var_errors}
                                            />
                                        </div>
                                        <div className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Duration')}</div>
                                            <SELECT
                                                property='expires_unit'
                                                value={credential.expires_unit}
                                                onChange={onChange_input}
                                                placeholder={t('Duration')}
                                                options={[
                                                    {value: 'day', text: t('Days')},
                                                    {value: 'month', text: t('Months')},
                                                    {value: 'year', text: t('Years')}
                                                ]}
                                                errors={var_errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <FORM_TOGGLE
                                label={t('Restrict claims')}
                                value={credential.restrict_claims}
                                options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                onChange={(value) => onChange_input(null, { name: 'restrict_claims', value })}
                                description={t('To obtain this credential, individuals cannot claim it directly. Instead, an Admin must add it to the holder\'s account.')}
                            />

                            {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Auto-verify')}
                                    value={credential.auto_verify}
                                    options={[{ value: 'YES', text: t('Yes') }, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'auto_verify', value })}
                                    description={t('This credential is automatically verified when claimed.')}
                                />
                            }

                            {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Additional field')}
                                    value={credential.credential_number_required}
                                    options={[{value: 'YES', text: t('Required')}, { value: 'NO', text: t('Not required') }]}
                                    onChange={(value) => onChange_input(null, { name: 'credential_number_required', value })}
                                    description={t('Add a field to capture additional information when a claim is made for this credential e.g. credential number, course ID, student ID, etc.')}
                                />
                            }

                            {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' && credential.credential_number_required === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('Specify the label to be displayed for this field. All values will be stored as text.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div id='id__fieldlabel' className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Field label')}</div>
                                            <INPUT
                                                property='credential_number_label'
                                                value={credential.credential_number_label || ''}
                                                onChange={onChange_input}
                                                placeholder={t('Field label')}
                                                maxLength={100}
                                                errors={var_errors}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            <FORM_TOGGLE
                                label={t('Credit value')}
                                value={credential.has_credit_value}
                                options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                onChange={(value) => onChange_input(null, { name: 'has_credit_value', value })}
                                description={t('Specify a credit value for this credential.')}
                            />

                            {credential.has_credit_value === 'YES' &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    <div className='detailsgroup__subgroup__description text--sm-regular'>{t('The credit value will be applied when this credential is used as a pre-qualification for a credit-based stacked credential.')}</div>
                                    <div className='detailsgroup__subgroup__content'>
                                        <div id='field__credit_value' className='detailsgroup__subgroup__content__item'>
                                            <div className='text--sm-medium'>{t('Credit value')}</div>
                                            <INPUT
                                                property='credit_value'
                                                value={credential.credit_value || ''}
                                                placeholder='1'
                                                onChange={onChange_input}
                                                errors={var_errors}
                                                disable_icon={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }

                            {credential.has_notification_email === 'YES' && 
                                <FORM_TOGGLE
                                    label={t('Issuance email')}
                                    value={credential.notify_issuance}
                                    options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'notify_issuance', value })}
                                    description={t('Notify the holder by email that this credential has been issued to them.')}
                                />
                            }

                            {credential.has_prequalifier !== 'YES' && credential.credits_required_for_issuance !== 'YES' && credential.can_select_contains_pii === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Contains PII')}
                                    value={credential.contains_pii}
                                    options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'contains_pii', value })}
                                    description={t('Indicate if the credential will contain PII.')}
                                />
                            }

                            {credential.has_prequalifier === 'YES' &&
                                <FORM_TOGGLE
                                    label={t('Show pre-qualifications')}
                                    value={credential.show_prequalifiers_in_certificate}
                                    options={[{value: 'YES', text: t('Yes')}, { value: 'NO', text: t('No') }]}
                                    onChange={(value) => onChange_input(null, { name: 'show_prequalifiers_in_certificate', value })}
                                    description={t('Show the pre-qualifications on the credential certificate to all viewers.')}
                                />
                            }

                            <div className='card__footer'>
                                <Button className='primary' onClick={onClick_nextstep}>{t('Continue to step 3')}</Button>
                            </div>
                        </>
                    </Form>
                    : null
            }

            <Processing display={display_processing} processingtext='' />

        </div>
    );
}