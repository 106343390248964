/* eslint-disable import/no-anonymous-default-export */
export default {
    api_admin: {
        url: 'https://rest-qa.credivera.com/admin'
    },
    api_credentials: {
        url: 'https://rest-qa.credivera.com/credentials'
    },
    api_dailycheck: {
        url: 'https://rest-qa.credivera.com/dailycheck'
    },
    api_documents: {
        url: 'https://rest-qa.credivera.com/documents'
    },
    api_external_report: {
        url: 'https://rest-qa.credivera.com/external-report'
    },
    api_identifiers: {
        url: 'https://rest-qa.credivera.com/identifiers'
    },
    api_identity_verification: {
        url: 'https://rest-qa.credivera.com/identity-verification'
    },
    api_indv: {
        url: 'https://rest-qa.credivera.com/indv'
    },
    api_integrations: {
        url: 'https://rest-qa.credivera.com/integrations'
    },
    api_issuer: {
        url: 'https://rest-qa.credivera.com/issuer'
    },
    api_location: {
        url: 'https://rest-qa.credivera.com/location'
    },
    api_onsite: {
        url: 'https://rest-qa.credivera.com/onsite'
    },
    api_org_indv: {
        url: 'https://rest-qa.credivera.com/org-indv'
    },
    api_org_profile: {
        url: 'https://rest-qa.credivera.com/org-profile'
    },
    api_reports: {
        url: 'https://rest-qa.credivera.com/reports'
    },
    api_requirements: {
        url: 'https://rest-qa.credivera.com/requirements'
    },
    api_requirements2: {
        url: 'https://rest-qa.credivera.com/requirements2'
    },
    api_security: {
        url: 'https://rest-qa.credivera.com/security'
    },
    api_storage: {
        url: 'https://rest-qa.credivera.com/storage'
    },
    api_transcripts: {
        url: 'https://rest-qa.credivera.com/transcripts'
    },
    api_translation: {
        url: 'https://rest-qa.credivera.com/translation'
    },
    api_verifiable_credentials: {
        url: 'https://rest-qa.credivera.com/verifiable-credentials'
    },

    iam: {
        url: 'https://auth-qa.credivera.com',
        client_id: 'alpha',
        realm: 'credivera'
    },
    language: {
        en: {
            name: 'English',
            i18n: 'en-US',
            moment: 'en-ca',
            datepicker: 'en-US',
            text: 'English'
        },
        fr: {
            name: 'French',
            i18n: 'fr-CA',
            moment: 'fr-ca',
            datepicker: 'fr-FR',
            text: 'Français'
        },
        es: {
            name: 'Spanish',
            i18n: 'es',
            moment: 'es',
            datepicker: 'es',
            text: 'Español'
        }
    },
    images: {
        url: 'https://imagedelivery.net/GLWlvH-xr3pnmldzciaCLA/',
        stage: '/qa',
        assets: {
            not_found: 'ebf6a661-8a04-4082-3a2b-579dc3218000',
            AuthenticID_logo: 'authenticid-logo.png',
            Avatar: '6e4119c5-2270-4117-3e0c-225c202c5a00',
            Bird_Construction: '4296a9d7-7f81-4d33-7e86-d683b5840200',
            Credivera_Horizontal_Blue: '5639ed9b-db64-40ab-8a93-5eaa1ffbd500',
            Credivera_Horizontal_White: '4296a9d7-7f81-4d33-7e86-d683b5840200',
            Credivera_White_Icon: 'ab289ed7-74cc-4a5f-bc9e-aa2231397800',
            Credivera_Blue_PNG: '72052f8a-00da-4eb1-4076-052c5f75ee00',
            mail_sent: '7f8f9dbd-00e3-4c74-eed7-f7cd2c131500',
            pdf_document: '5c5e5923-61c7-4aa7-bca7-52d584eebd00',
            onboarding_1: '946e372b-5b4c-4169-df92-bcd46e5d9a00',
            onboarding_2: '6ee4cbe7-a395-4bc7-d5df-b59d0a94bb00',
            onboarding_3: 'onboarding_3.png',
            sample_credential_logo: '190dca30-d563-43b7-3f9f-14a604c25600'
        },
        android_chrome_192x192: '687be449-f661-4e8d-d109-851e4ab42200',
        android_chrome_384x384: '91cb0891-2705-4bbb-6659-921a5b926700',
        apple_touch_icon: '75c32b2f-2c5e-43ea-5af1-1b4bc5f64200',
        favicon_16x16: '27f27c5e-940c-42fc-ffe6-11f0f206bb00',
        favicon_32x32: '6b01878f-71a0-4d81-3421-d28dc13ed600',
        favicon: '',
        mstile_150x150: 'accebed5-f19c-48cf-beeb-136355e47c00',
        safari_pinned_tab: '727caf4a-d5d7-46e6-159a-cf73ff4f2b00'
    }
};
