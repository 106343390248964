import React, { useEffect, useState } from 'react';
import API from 'libs/api-lib';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import Processing from 'components/cmp_processing';
import { CHECKBOX, FORM_DATEPICKER, FORM_SELECT, FORM_SELECT_RADIO } from 'components/cmp_form/cmp_form';
import { useTranslation } from 'react-i18next';
import 'i18n';
import datelib from 'libs/date-lib';
import auth from 'libs/auth-lib';
import Icon from 'components/cmp_icon';
import _ from 'lodash';

import CMP_CREDIT_ACTIVITY_LIST from 'components/cmp_credit_activity_list/cmp_credit_activity_list';
import CMP_VERIFIED_IDENTITY_DISPLAY from 'components/cmp_verified_identity_display/cmp_verified_identity_display';

import './mdl_batch_credits.css';

function MDL_BATCH_CREDITS({ is_open, onClose, credential_details, onChange }) {

    //  variable declarations ------------------------------------------------------------------------------------------
    const { t } = useTranslation('public');

    const [ var_individual_options, set_individual_options ] = useState([]);
    const [ var_selected_individual_credentials, set_selected_individual_credentials ] = useState([]);
    const [ var_error, set_error ] = useState(null);
    const [ var_processing, set_processing ] = useState(false);
    const [ var_selected_assignment, set_selected_assignment ] = useState('ALL');
    const [ var_assignment_options, set_assignment_options ] = useState([]);
    const [ var_all_individuals, set_all_individuals ] = useState(false);
    const [ var_disable_add, set_disable_add ] = useState(false);
    const [ var_modal_close_on_escape, set_modal_close_on_escape ] = useState(true);
    const [ var_modal, set_modal ] = useState(null);
    const [ var_credit_activity_entries, set_credit_activity_entries ] = useState([]);
    const [ var_activity_date, set_activity_date ] = useState(null);


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if(var_modal){

            //  create list of focusable elements within the modal
            var var_elements = var_modal.querySelectorAll('.modal__content, button:not([disabled]), input[type="text"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])');
            var var_firstelement = var_elements[0];
            var var_lastelement = var_elements[var_elements.length - 1];

            //  set focus to first element within the modal
            var_firstelement.focus();

            //  if current focused item is the last in the list, next focused item is first in the list and vise-versa
            var_modal.addEventListener('keydown', function(e) {
                if (e.key === 'Tab') {
                    if (e.shiftKey) /* shift + tab */ {
                        if (document.activeElement === var_firstelement) {
                            var_lastelement.focus();
                            e.preventDefault();
                        }
                    } else /* tab */ {
                        if (document.activeElement === var_lastelement) {
                            var_firstelement.focus();
                            e.preventDefault();
                        }
                    }
                }
            });

        }
    }, [var_modal]);

    useEffect(() => {
        if (is_open && credential_details) {
            // reset and populate options
            set_modal(document.querySelector('#mdl_batch_credits'));
            set_individual_options([]);
            set_selected_individual_credentials([]);
            set_error(null);
            set_processing(false);
            set_selected_assignment('ALL');
            set_all_individuals(false);
            set_disable_add(false);
            set_activity_date(null);
            populate_credential_credit_list()
            set_credit_activity_entries([]);
            if (auth.has_orgaccess('ORG-LOC-ADMIN', 'read')) {
                populate_assignments();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_open, credential_details]);

    useEffect(() => {
        if (is_open) {
            set_all_individuals(false);
            populate_individuals();
            set_selected_individual_credentials([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_selected_assignment, is_open]);

    useEffect(() => {
        if (is_open && var_error) {
            document.getElementById('mdl_batch_credits').scrollTo(0, 0);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_error]);


    //  async functions ------------------------------------------------------------------------------------------------

    async function populate_individuals() {
        try {
            let results = await API_get_individuals_to_add_to_credit_tracking_credential();
            let transformed = results.map(item => ({
                value: item.individual_credential_id,
                primary_text:
                        <CMP_VERIFIED_IDENTITY_DISPLAY
                            individual_name={item.individual_name}
                            verified_identity_credential_id={item.identity_verification_credential_id}
                            display_nonclickable_icon={true}
                        />,
                secondary_text: item.email,
                additional_search_values: [item.individual_name]
            }));
            set_individual_options(transformed);
            if (transformed.length === 0) {
                set_disable_add(true);
                set_error(t('There are no individuals to add credits for'));
            } else {
                set_disable_add(false);
            }
        } catch (e) {
            set_error(t('There was a problem loading.  Please try again later'));
            console.log(e);
        }
    }

    async function populate_assignments() {
        try {
            let results = await API_get_assignment_list_for_org();
            results = [{ value: 'ALL', text: t('All assignments') }].concat(results);
            set_assignment_options(results);
        } catch (e) {
            set_error(t('There was a problem loading.  Please try again later'));
            console.log(e);
        }
    }

    async function save_batch_credits(credit_activity_entries) {
        set_processing(true);

        try {
            for (let individual_credential_id of var_selected_individual_credentials) {
                await API_post_credential_credit_activities(individual_credential_id, credit_activity_entries);
            }
            onChange && onChange();
        } catch (e) {
            set_error(t('There was a problem saving.  Please try again later'));
            console.log(e);
        }
        set_processing(false);
    }

    async function populate_credential_credit_list() {
        set_processing(true);
        try {
            let results = await API_get_credential_credit_list();
            set_credit_activity_entries(results);
        } catch (e) {
            console.log(e);
            set_error(t('There was a problem fetching data.  Please try again later'));
        }
        set_processing(false);
    }

    //  API calls ------------------------------------------------------------------------------------------------------

    function API_get_individuals_to_add_to_credit_tracking_credential() {
        return API.get('org-indv', `/get-individuals-to-add-to-credit-tracking-credential/${credential_details.id}` + (var_selected_assignment !== 'ALL' ? `/${var_selected_assignment}` : ''));
    }

    function API_get_assignment_list_for_org() {
        return API.get('location', '/get-assignment-list-for-org');
    }

    function API_post_credential_credit_activities(individual_credential_id, credit_activity_entries) {
        return API.post('credentials', '/post-credential-credit-activities/',
            {
                queryStringParameters: {
                    tz: datelib.timezone
                },
                body: {
                    individual_credential_id,
                    activities: credit_activity_entries
                }
            });
    }

    function API_get_credential_credit_list() {
        return API.get('credentials', `/get-credential-credit-list/${credential_details.id}`);
    }

    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_individuals(value) {
        set_selected_individual_credentials(value);
        set_error(null);
    }

    function onChange_assignment(e, { name, value }) {
        set_selected_assignment(value);
        set_error(null);
    }

    function onChange_date(e, { value }) {
        set_error(null);
        set_activity_date(value);
    }

    async function onClick_add() {
        if (var_selected_individual_credentials.length === 0 && !var_all_individuals) {
            set_error(t('Please select at least one individual'));
            return;
        }

        if (!var_credit_activity_entries.some(entry => entry.credits && entry.credits > 0)) {
            set_error(t('Please enter a credit'));
            return;
        }

        let credit_activity_entries = _.cloneDeep(var_credit_activity_entries);
        for (let credit_activity of credit_activity_entries) {
            credit_activity.activity_date = var_activity_date ? datelib.localdate_to_utc_midnight_epoch(var_activity_date) : null;
        }

        await save_batch_credits(credit_activity_entries);
    }

    function onChange_all_individuals() {
        set_error(null);
        if (!var_all_individuals) {
            set_selected_individual_credentials(var_individual_options.map(item => item.value));
        } else {
            set_selected_individual_credentials([]);
        }
        set_all_individuals(!var_all_individuals);
    }

    function onChange_activity(activity) {
        if (!activity) return;

        const updated_activity = var_credit_activity_entries.map(item => item.id === activity.id ? activity : item);
        set_credit_activity_entries(updated_activity);
    }

    // RENDER APP ======================================================================================================

    return (
        <Modal
            id='mdl_batch_credits'
            dimmer='inverted'
            onClose={onClose}
            open={is_open}
            closeOnEscape={var_modal_close_on_escape}
            closeOnDimmerClick={true}>

            <div className='modal__header'>
                <div className='modal__header__left'>
                    <div className='text--xl-medium' id='hdr_creddetails_add_credits'>{t('Batch credits')}</div>
                </div>
            </div>

            <Form className='modal__content' id='form_creddetails_add_credits' aria-labelledby='hdr_creddetails_add_credits' tabIndex='0'>
                {var_error &&
                    <Message error
                        icon={<Icon name='error' className='icon' />}
                        header={var_error}
                    />
                }
                {auth.has_orgaccess('ORG-LOC-ADMIN', 'read') &&
                <div>
                    <FORM_SELECT
                        property='assignment'
                        label={t('Assignment')}
                        value={var_selected_assignment || ''}
                        onChange={onChange_assignment}
                        onOpen={() => set_modal_close_on_escape(false)}
                        onClose={() => set_modal_close_on_escape(true)}
                        placeholder={t('Select')}
                        options={var_assignment_options}
                        disabled={false}
                        errors={[]}
                        description={t('Choose an assignment to select individuals from.')}
                    />
                </div>
                }
                <FORM_SELECT_RADIO
                    name='individuals'
                    property='individuals'
                    label={t('Individuals')}
                    value={var_selected_individual_credentials}
                    options={var_individual_options}
                    single_or_multiple='MULTIPLE'
                    onChange={onChange_individuals}
                    placeholder={t('Search')}
                    disabled={var_all_individuals}
                    errors={[]}
                    description={t('Select individuals to receive credits')}
                />
                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'></div>
                    <CHECKBOX
                        name='all_individuals'
                        label={t('Select all individuals')}
                        checked={var_all_individuals}
                        onChange={onChange_all_individuals}
                        disabled={var_individual_options.length === 0}
                    />
                </div>
                <FORM_DATEPICKER
                    property='activity_date'
                    label={t('Date')}
                    value={var_activity_date || ''}
                    onChange={(e, {value}) => onChange_date(e, { value })}
                    onOpen={() => set_modal_close_on_escape(false)}
                    onClose={() => set_modal_close_on_escape(true)}
                    placeholder={t('YYYY-MM-DD')}
                    errors={[]}
                    description={t('Enter the date on which the credit was earned.')}
                />

                <div className='detailsgroup'>
                    <div className='detailsgroup__label text--sm-medium'>{t('Credits')}</div>
                    <Form.Field className='detailsgroup__value'>
                        <div className='detailsgroup__description text--sm-regular'>{t('Enter the credits earned for each task. The entered credits will be applied to all selected individuals.')}</div>
                        <CMP_CREDIT_ACTIVITY_LIST
                            activities={var_credit_activity_entries}
                            onChange_activity={onChange_activity}
                            display_date={false}
                        />
                    </Form.Field>
                </div>
            </Form>

            <div className='modal__footer'>
                <div className='card__header__left footer__btns'>
                    <Button className='primary' onClick={onClick_add} disabled={var_disable_add}>{t('Save')}</Button>
                    <Button className='secondary' onClick={onClose}>{t('Cancel')}</Button>
                </div>
            </div>

            <Processing display={var_processing} processingtext={t('Processing')} />

        </Modal>
    );
}

export default MDL_BATCH_CREDITS;