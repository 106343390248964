import React, { useState, useEffect } from 'react';
import API from 'libs/api-lib';
import { useLocation, useNavigate } from 'react-router-dom';
import Moment from 'moment';
import Routes from './routes';
import Icon from './components/cmp_icon';
import Logo from 'components/cmp_logo';
import { useTranslation } from 'react-i18next';
import './i18n';
import auth from './libs/auth-lib';
import datelib from './libs/date-lib';
import config from './config';

import './ui_app.css';


function App() {

    //  variable declarations --------------------------------------------------------------------------------------------

    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('public');
    const [ var_hamburger, set_hamburger ] = useState(false);
    const [ var_activemenuitem, set_activemenuitem ] = useState('dashboard');
    const [ var_systemstatus, set_systemstatus ] = useState('ONLINE');
    const [ var_userstatus, set_userstatus ] = useState('OFFLINE');
    const [ var_assignments, set_assignments ] = useState([]);
    const [ var_assignments_open, set_assignments_open ] = useState(false);
    const [ var_orgmenu, set_orgmenu ] = useState([]);
    const [ var_organization_open, set_organization_open ] = useState(false);
    const [ var_reports_open, set_reports_open ] = useState(false);

    const OFFLINE_PATHS = ['/public/', '/report/', '/invite', '/saml', '/download', '/login', '/terms', '/verification/'];


    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        if (var_userstatus === 'OFFLINE' && auth.is_loggedin && !window.location.pathname.startsWith('/login')) {
            // user refreshed, just change the status to online
            set_userstatus('ONLINE');
            set_activemenuitem(sessionStorage.getItem('activemenuitem'));
        } else if (var_userstatus === 'LOGGEDIN') {
            // user just logged in, get authentication
            let has_deep_link = !!sessionStorage.getItem('deep_link');
            get_authentication();
            if (!has_deep_link) {
                set_activemenuitem('dashboard');
                sessionStorage.setItem('activemenuitem', 'dashboard');
            }
        } else if (var_userstatus === 'OFFLINE' && !OFFLINE_PATHS.some(item => window.location.pathname.startsWith(item))) {
            // user just came to the app or logged out, redirect to the login page
            set_appDefaultLanguage(null, true);
            navigate('/login');
        } else if (var_userstatus === 'ONLINE') {
            // user is online, add polling to make sure user is active
            const var_interval = setInterval(() => {
                if (Math.floor(Date.now() / 1000) - localStorage.getItem('activetime') > 1800) {
                    onClick_signout();
                }
            }, 60000);
            return () => clearInterval(var_interval);
        } else {
            // user just came to the app to a report page, reset page or invite page.  set language
            set_appDefaultLanguage(null, true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [var_userstatus]);

    useEffect(() => {
        if ((window.location.pathname.indexOf('saml')||(window.location.pathname.indexOf('oauth'))) >= 0) {
            //  do nothing (SAML handles authentication)
        } else {
            if (!sessionStorage.getItem('authentication')) {
                get_systemstatus();
            } else {
                authenticatepageload();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname]);

    useEffect(() => {
        let menuitem = null;
        if (location.pathname.startsWith('/dashboard')) {
            menuitem = 'dashboard';
        } else if (location.pathname.startsWith('/credentials')) {
            menuitem = 'credentials';
        } else if (location.pathname.startsWith('/requirements2')) {
            menuitem = 'requirements2';
        } else if (location.pathname.startsWith('/requirements')) {
            menuitem = 'requirements';
        } else if (location.pathname.startsWith('/profile')) {
            menuitem = 'profile';
        } else if (location.pathname.startsWith('/assignments/') && location.pathname.length >= 49) {
            menuitem = location.pathname.substring(1, 49);
            set_assignments_open(true);
        } else {
            // might be org menu
            let orgmenuitem = var_orgmenu.find(item => location.pathname === item.path || location.pathname.startsWith(item.path + '/'));

            if (!orgmenuitem) return;
            menuitem = orgmenuitem.grouping + orgmenuitem.name;
            if (orgmenuitem.grouping === 'Organization') {
                set_organization_open(true);
            } else {
                set_reports_open(true);
            }
        }
        set_activemenuitem(menuitem);
        sessionStorage.setItem('activemenuitem', menuitem);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, var_orgmenu]);


  //  functions calls --------------------------------------------------------------------------------------------------

    async function authenticatepageload() {
        try {

            //  check system status
            let status = await get_systemstatus();
            if (status && status.systemstatus === 'OFFLINE') {
                await onClick_signout();
                return;
            }

            //  get TerraHub user details
            let result = await API_get_authentication();

            //check if account has been archived
            if (result === 'NO ACTIVE ACCOUNT') {
                alert(t('NO ACTIVE ACCOUNT'));
            }

            await set_authentication(result, true);
            set_assignments(result.assignments);
            set_orgmenu(result.orgmenu);
        } catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }
    }

    async function get_systemstatus() {
        try {
            let result = await API_get_systemstatus();
            set_systemstatus(result);
            return result;
        } catch (e) {
            console.log(e);
        }
    }

    async function get_authentication() {
        let new_location = sessionStorage.getItem('deep_link') || '/dashboard';
        sessionStorage.removeItem('deep_link');

        if (auth.authenticated) {
            // already have authentication
            set_userstatus('ONLINE');
            navigate(new_location);
            return;
        }

        let authentication = await API_get_authentication();
        if (!authentication) {
            // If we are unable to get authentication, sign user out
            onClick_signout();
            return;
        }
        await set_authentication(authentication); // save authentication to storage
        set_userstatus('ONLINE');
        navigate(new_location);
    }

    //This method is needed in order to reset the left profile information on name change
    async function reset_authentication() {
        let authentication = await API_get_authentication();
        await set_authentication(authentication); // save authentication to storage
    }

    /**
     * @param {Object} authentication
     * @param {Boolean} [set_individual_language]
     * @returns {Promise}
     */
    async function set_authentication(authentication, set_individual_language = false) {
        authentication.authenticated = true;

        let default_language = 'en';
        if (authentication.default_language && ['en', 'fr', 'es'].includes(authentication.default_language)) {
            default_language = authentication.default_language;
        } else {
            const browser_language = window.navigator.language.split('-')[0];
            if (browser_language === 'en' || browser_language === 'fr' || browser_language === 'es') {
                default_language = browser_language;
            }
            if (authentication.id && set_individual_language) {
                await API_put_indv_settings(default_language);
            }
        }
        authentication = await auth.parse_authentication_result(authentication);
        authentication.language = config.language[default_language];
        set_appDefaultLanguage(default_language);
        auth.set_authentication(authentication);
        auth.clear();
    }

    function get_container_classname() {
        if (OFFLINE_PATHS.some(item => window.location.pathname.startsWith(item)) || !auth.is_loggedin) {
            return 'app offline notfound';
        } else {
            return 'app online';
        }
    }


    //  API calls --------------------------------------------------------------------------------------------------------

    function API_get_authentication() {
        return API.get('admin', '/get-authentication');
    }

    function API_get_systemstatus() {
        return API.get('admin', '/get-systemstatus?tz='+ datelib.querystring_timezone);
    }

    function API_put_indv_settings(default_language) {
        return API.put('indv', '/put-indv-settings', { body: { default_language } });
    }

    //  event functions --------------------------------------------------------------------------------------------------

    function set_appDefaultLanguage(default_language, set_to_browser_language = false) {
        if (default_language === 'en' || default_language === 'fr' || default_language === 'es') {
            if (default_language === 'fr' || default_language === 'es') {
                const locales = require('moment/locale/' + config.language[default_language].moment);
                Moment.updateLocale(config.language[default_language].moment, locales);
            } else {
                Moment.locale(config.language[default_language].moment);
            }
            i18n.changeLanguage(config.language[default_language].i18n);
            document.documentElement.lang = config.language[default_language].i18n.split('-')[0];
        } else if (set_to_browser_language) {
            let browser_language = window.navigator.language.split('-')[0];
            set_appDefaultLanguage(browser_language === 'fr' ? 'fr' : browser_language === 'es' ? 'es' : 'en');
        }
    }

    function onClick_hamburger() {
        set_hamburger(!var_hamburger)
    }

    async function onClick_signout() {
        await auth.logout(navigate);
    }

    function onClick_option(menuitem, var_route) {
        set_activemenuitem(menuitem);
        sessionStorage.setItem('activemenuitem', menuitem);
        navigate(var_route);
        set_hamburger(false);
    }

    function onClick_help_center() {
        if (JSON.parse(sessionStorage.getItem('authentication')).language.name === 'French') {
            window.open('https://helpcenter.credivera.com/fr-ca/knowledge', '_blank');
        } else {
            window.open('https://helpcenter.credivera.com/en/knowledge', '_blank');
        }
    }

    function onKeyDown(event, menuitem, route) {
        if (event.key === 'Enter') {
            switch (menuitem) {
                case 'assignments':
                    set_assignments_open(!var_assignments_open);
                    break;
                case 'organization':
                    set_organization_open(!var_organization_open);
                    break;
                case 'reports':
                    set_reports_open(!var_reports_open);
                    break;
                case 'helpcenter':
                    onClick_help_center()
                    break;
                case 'signout':
                    onClick_signout();
                    break;
                default:
                    onClick_option(menuitem, route)
                    break;
            }
        }
    }

    // RENDER APP ========================================================================================================

    return (
        <>
            {var_systemstatus && var_systemstatus.systemstatus === 'OFFLINE' ?
                <div className='crd_systemalert_wrapper'>
                    <div className='crd_systemalert'>
                        <div className='crd_systemalert_text'>{var_systemstatus.outagemessage}</div>
                        <div className='crd_systemalert_timeframe'>{var_systemstatus.outagewindow}</div>
                    </div>
                </div>
            :
                <div className={get_container_classname()}>
                        <>
                            {auth.is_loggedin &&
                                <nav aria-label={t('Main menu')} className={'nav text--md-medium' + (var_hamburger ? ' nav__expanded--display' : ' nav__expanded--hide')}>
                                    <div className='nav__header'>
                                        {auth.custom_logo
                                        ?
                                            <div className='nav__header__portal custom_logo' aria-hidden='true' >
                                                <Logo
                                                    targetid={auth.logo_organization_id}
                                                    classification='logo'
                                                    filename={auth.custom_logo}
                                                    ready={!!auth.custom_logo}
                                                    alt=''
                                                />
                                            </div>
                                        :
                                        <div className='nav__header__portal text--xl-bold' aria-hidden='true' >
                                            <img src={`${config.images.url}${config.images.assets.Credivera_White_Icon}${config.images.stage}`} alt='Credivera logo' />
                                            {t('My Credivera')}
                                        </div>
                                        }
                                        <div className='nav__header__hamburger' onClick={() => onClick_hamburger()}>
                                            {var_hamburger
                                            ? <Icon name='hamburger_close' className='icon__hamburger_close' alt='close menu icon' />
                                            : <Icon name='hamburger' className='icon__hamburger' alt='' />
                                            }
                                        </div>
                                    </div>
                                    <div className='nav__expanded' id='navigation'>

                                    <div className='nav__expanded__options'>

                                        <div id='NavLink1' tabIndex='0' role='link'
                                            className={'nav__expanded__option' + (var_activemenuitem === 'dashboard' ? ' active' : '')}
                                            onClick={() => onClick_option('dashboard', '/dashboard')}
                                            onKeyDown={(e) => onKeyDown(e, 'dashboard', '/dashboard')}>
                                            <Icon name='dashboard' className='icon__menuitem' alt='' />
                                            <div className='nav__expanded__option__primarytext text--md-medium'>{t('Dashboard')}</div>
                                        </div>
                                        <div id='NavLink2' tabIndex='0' role='link'
                                            className={'nav__expanded__option' + (var_activemenuitem === 'credentials' ? ' active' : '')}
                                            onClick={() => onClick_option('credentials', '/credentials/all')}
                                            onKeyDown={(e) => onKeyDown(e, 'credentials', '/credentials/all')}>
                                            <Icon name='credentials_menu' className='icon__menuitem' alt='' />
                                            <div className='nav__expanded__option__primarytext text--md-medium'>{t('Credentials')}</div>
                                        </div>
                                        {auth.menu_documents &&
                                            <div id='NavLink3' tabIndex='0' role='link'
                                                className={'nav__expanded__option' + (var_activemenuitem === 'documents' ? ' active' : '')}
                                                onClick={() => onClick_option('documents', '/documents')}
                                                onKeyDown={(e) => onKeyDown(e, 'documents', '/documents')}>
                                                <Icon name='documents_menu' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Documents')}</div>
                                            </div>
                                        }

                                        {auth.menu_requirements &&
                                            <div id='NavLink4' tabIndex='0' role='link'
                                                className={'nav__expanded__option' + (var_activemenuitem === 'requirements' ? ' active' : '')}
                                                onClick={() => onClick_option('requirements', '/requirements')}
                                                onKeyDown={(e) => onKeyDown(e, 'requirements', '/requirements')}>
                                                <Icon name='requirements_menu' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Requirements')}</div>
                                            </div>
                                        }

                                        {auth.menu_requirements2 &&
                                            <div id='NavLink5' tabIndex='0' role='link'
                                                className={'nav__expanded__option' + (var_activemenuitem === 'requirements2' ? ' active' : '')}
                                                onClick={() => onClick_option('requirements2', '/requirements2')}
                                                onKeyDown={(e) => onKeyDown(e, 'requirements2', '/requirements2')}>
                                                <Icon name='requirements_menu' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Requirements 2.0')}</div>
                                            </div>
                                        }

                                        {auth.menu_transcripts &&
                                            <div id='NavLink6' tabIndex='0' role='link'
                                                className={'nav__expanded__option' + (var_activemenuitem === 'transcripts' ? ' active' : '')}
                                                onClick={() => onClick_option('transcripts', '/transcripts')}
                                                onKeyDown={(e) => onKeyDown(e, 'transcripts', '/transcripts')}>
                                                <Icon name='transcripts_menu' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Transcripts')}</div>
                                            </div>
                                        }

                                        {var_assignments[0] &&
                                            <div id='NavLink7' tabIndex='0' role='button' aria-expanded={var_assignments_open}
                                                className='nav__expanded__option'
                                                onClick={() => set_assignments_open(!var_assignments_open)}
                                                onKeyDown={(e) => onKeyDown(e, 'assignments', null)}>
                                                <Icon name='assignments' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Assignments')}</div>
                                                <div style={ {flex: 1 }} />
                                                {var_assignments_open
                                                    ? <Icon name='chevron_up' className='icon__nav_chevron' alt='' />
                                                    : <Icon name='chevron_down' className='icon__nav_chevron' alt='' />
                                                }
                                            </div>
                                        }

                                        {var_assignments_open && var_assignments.map((item, index) =>
                                            <div id={`NavLink7_${index}`} tabIndex='0' role='link' key={'menu_assignment_' + item.id}
                                                className={'nav__expanded__option' + (var_activemenuitem.startsWith('assignments/' + item.id) ? ' active' : '')}
                                                onClick={() => onClick_option('assignments/' + item.id, '/assignments/' + item.id)}
                                                onKeyDown={(e) => onKeyDown(e, `assignments/${item.id}`, `/assignments/${item.id}`)}>
                                                <div className='icon__menuitem'></div>
                                                <div className='nav__expanded__option__primarytext text--sm-medium'>{item.name}</div>
                                            </div>
                                        )}

                                        {var_orgmenu.some(item => item.grouping === 'Organization') &&
                                            <div id='NavLink8' tabIndex='0' role='button' aria-expanded={var_organization_open}
                                                className='nav__expanded__option'
                                                onClick={() => set_organization_open(!var_organization_open)}
                                                onKeyDown={(e) => onKeyDown(e, 'organization', null)}>
                                                <Icon name='nav_organization' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Organization')}</div>
                                                <div style={ {flex: 1 }} />
                                                {var_organization_open
                                                    ? <Icon name='chevron_up' className='icon__nav_chevron' alt='' />
                                                    : <Icon name='chevron_down' className='icon__nav_chevron' alt='' />
                                                }
                                            </div>
                                        }

                                        {var_organization_open && var_orgmenu.some(item => item.grouping === 'Organization') && var_orgmenu.filter(item => item.grouping === 'Organization').map((item, index) =>
                                            <div id={`NavLink8_${index}`} tabIndex='0' role='link' key={'menu_organization_' + item.name}
                                                className={'nav__expanded__option' + (var_activemenuitem === (item.grouping + item.name) ? ' active' : '')}
                                                onClick={() => onClick_option(item.grouping + item.name, item.path)}
                                                onKeyDown={(e) => onKeyDown(e, item.grouping + item.name, item.path)}>
                                                <div className='icon__menuitem'></div>
                                                <div className='nav__expanded__option__primarytext text--sm-medium'>{t(item.name)}</div>
                                            </div>
                                        )}

                                        {var_orgmenu.some(item => item.grouping === 'Reports') &&
                                            <div id='NavLink9' tabIndex='0' role='button' aria-expanded={var_reports_open}
                                                className='nav__expanded__option'
                                                onClick={() => set_reports_open(!var_reports_open)}
                                                onKeyDown={(e) => onKeyDown(e, 'reports', null)}>
                                                <Icon name='nav_reports' className='icon__menuitem' alt='' />
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{t('Reports')}</div>
                                                <div style={ {flex: 1 }} />
                                                {var_reports_open
                                                    ? <Icon name='chevron_up' className='icon__nav_chevron' alt='' />
                                                    : <Icon name='chevron_down' className='icon__nav_chevron' alt='' />
                                                }
                                            </div>
                                        }

                                        {var_reports_open && var_orgmenu.some(item => item.grouping === 'Reports') && var_orgmenu.filter(item => item.grouping === 'Reports').map((item, index) =>
                                            <div id={`NavLink9_${index}`} tabIndex='0' role='link' key={'menu_reports_' + item.name}
                                                className={'nav__expanded__option' + (var_activemenuitem === (item.grouping + item.name) ? ' active' : '')}
                                                onClick={() => onClick_option(item.grouping + item.name, item.path)}
                                                onKeyDown={(e) => onKeyDown(e, item.grouping + item.name, item.path)}>
                                                <div className='icon__menuitem'></div>
                                                <div className='nav__expanded__option__primarytext text--sm-medium'>{t(item.name)}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='nav__expanded__divider'></div>

                                    <div className='nav__expanded__account'>

                                        <div id='NavLink10' tabIndex='0' role='link'
                                            className={'nav__expanded__option' + (var_activemenuitem === 'profile' ? ' active' : '')}
                                            onClick={() => onClick_option('profile', '/profile')}
                                            onKeyDown={(e) => onKeyDown(e, 'profile', '/profile')}>
                                            <Icon name='account_setting' className='icon__account_setting' alt='' />
                                            <div className='nav__expanded__option__textwrapper'>
                                                <div className='nav__expanded__option__primarytext text--md-medium'>{auth.firstname} {auth.lastname}</div>
                                                <div className='nav__expanded__option__secondarytext text--xs-regular profile_email'>{auth.email}</div>
                                            </div>
                                        </div>

                                        <div id='NavLink11' tabIndex='0' role='link'
                                            className='nav__expanded__option'
                                            onClick={onClick_help_center}
                                            onKeyDown={(e) => onKeyDown(e, 'helpcenter', null)}>
                                            <Icon name='question_circle' className='icon__helpcenter' alt='' />
                                            <div className='nav__expanded__option__primarytext text--md-medium'>{t('Help Center')}</div>
                                        </div>

                                        <div id='NavLink12' tabIndex='0' role='link'
                                            className='nav__expanded__option'
                                            onClick={onClick_signout}
                                            onKeyDown={(e) => onKeyDown(e, 'signout', null)}>
                                            <Icon name='signout' className='icon__signout' alt='' />
                                            <div className='nav__expanded__option__primarytext text--md-medium'>{t('Sign out')}</div>
                                        </div>

                                    </div>
                                    </div>
                                    {auth.custom_logo &&
                                        <div className='nav__footer text--xs-regular'>
                                            {t('Powered by')}
                                            <div className='text--xl-bold' aria-hidden='true' >
                                                <img src={`${config.images.url}${config.images.assets.Credivera_Horizontal_White}${config.images.stage}`} alt='Credivera logo' />
                                            </div>
                                        </div>
                                    }
                                </nav>
                            }

                            <main className={'cont' + (var_hamburger ? ' nav__expanded--display' : ' nav__expanded--hide')}>
                                {var_systemstatus && var_systemstatus.systemstatus === 'PENDING_OFFLINE' && var_systemstatus.preoutagemessage &&
                                    <div className='systemalert'>{var_systemstatus.preoutagemessage} {var_systemstatus.outagewindow}</div>
                                }
                                <Routes appProps={{ set_userstatus, set_appDefaultLanguage, authenticatepageload, reset_authentication }} />
                            </main>
                        </>
                </div>
            }
        </>
    );
}

export default App;
